import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Pagination } from 'swiper';

export default function InfoSlide() {
  return (
    <div className="">
      <Swiper
        spaceBetween={30}
        effect={'fade'}
        // navigation={true}
        pagination={{
          clickable: true
        }}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
          stopOnLastSlide: false,
          waitForTransition: true
        }}
        modules={[EffectFade, Autoplay, Pagination]}
        className="mySwiper">
        <SwiperSlide>
          <div className="bg-slide1 bg-no-repeat md:mt-5 bg-contain md:w-[100%] md:h-96 bg-right bg-[#677992] md:relative">
            <div className="px-5 md:px-16 py-10 md:py-[unset] md:absolute md:top-48">
              <p className="text-light text-2xl md:text-4xl md:w-[550px] font-UbuntuSemiBold">
                Pledged to protect nature
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-slide2 bg-no-repeat md:mt-5 bg-contain md:w-[100%] md:h-96 text-left bg-right bg-dark md:relative">
            <div className="px-5 md:px-16 py-10 md:py-[unset] md:absolute md:top-48">
              <p className="text-light text-2xl md:text-4xl md:w-[550px] font-UbuntuSemiBold">
                Organic products straight <br />
                from farms
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-slide3 bg-no-repeat md:mt-5 bg-contain md:w-[100%] md:h-96 text-left bg-center bg-black md:bg-right md:relative">
            <div className="px-5 md:px-16 py-10 md:py-[unset] md:absolute md:top-48">
              <p className="text-light text-2xl md:text-4xl md:w-[550px] font-UbuntuSemiBold">
                Splash in the radiant colours <br />
                of nature
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
