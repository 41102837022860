import React, { useState } from 'react';

const FORM_ENDPOINT = 'https://public.herotofu.com/v1/87d78050-fe41-11ed-b2e2-c10354b56774';

export default function EnquiryForm() {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <div className="md:px-96 py-10 text-center">
        <div className="text-2xl text-dark font-UbuntuSemiBold">Thank you!</div>
        <div className="text-md text-dark font-UbuntuRegular">We&apos;ll be in touch soon.</div>
      </div>
    );
  }
  return (
    <div className="md:px-96 py-10 text-center">
      <p className="text-center text-dark text-3xl pb-4 font-UbuntuSemiBold">Enquiry Form</p>
      <form
        action={FORM_ENDPOINT}
        onSubmit={handleSubmit}
        method="POST"
        target="_blank"
        className="mx-auto justify-center">
        <div className="mb-3 md:py-2 flex justify-between space-x-2 md:space-x-4">
          <input
            type="text"
            placeholder="Your name"
            name="name"
            className="px-3 py-3 placeholder-black text-default relative bg-white rounded text-sm border-2 w-1/2 border-dark"
            required
          />
          <input
            type="email"
            placeholder="Email"
            name="email"
            className="px-3 md:py-3 placeholder-black text-default relative bg-white rounded text-sm border-2 w-1/2 border-dark"
            required
          />
        </div>
        <div className="mb-3 md:py-2 flex justify-between space-x-2 md:space-x-4">
          <input
            type="number"
            placeholder="Contact number"
            name="contact"
            className="px-3 py-3 placeholder-black text-default relative bg-white rounded text-sm border-2 w-1/2 border-dark"
            required
          />
          <input
            type="number"
            placeholder="Whatsapp number"
            name="whatsapp"
            className="px-3 py-3 placeholder-black text-default relative bg-white rounded text-sm border-2 w-1/2 border-dark"
            required
          />
        </div>
        <div className="mb-3 py-2 flex justify-between space-x-2 md:space-x-4">
          <textarea
            placeholder="Your message"
            name="message"
            maxLength={200}
            className="px-3 py-3 placeholder-black text-default relative bg-white rounded text-sm border-2 w-full border-dark"
            required
          />
        </div>
        <div className="mb-3 pt-0">
          <button
            className="bg-dark px-8 py-2 rounded-md text-light font-UbuntuRegular"
            type="submit">
            Send a message
          </button>
        </div>
      </form>
    </div>
  );
}
