import React from 'react';
import { motion } from 'framer-motion';

export default function InfoSection() {
  const cardVariants = {
    offscreen: {
      opacity: 0
    },
    onscreen: {
      opacity: 1,
      transition: {
        duration: 1.5
      }
    }
  };
  return (
    <div>
      <div className="px-6 md:px-16">
        <div className="border-b-[1px] border-dark py-2 md:p-5">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}>
            <motion.div variants={cardVariants} className="grid md:grid-cols-2">
              <img src={require('../../assets/images/home1.jpg')} className="h-44 m-auto" />
              <p className="text-dark text-lg md:text-2xl py-2 md:py-10 font-UbuntuSemiBold">
                Green Rangers Consulting Private Limited Company germinates from a dream to make our
                planet a better place for its inmates. We aim to provide a range of products and
                solutions that support and embrace sustainability.
              </p>{' '}
            </motion.div>
          </motion.div>
        </div>
        <div className="border-b-[1px] border-dark py-2 md:p-5">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}>
            <motion.div variants={cardVariants} className="grid md:grid-cols-2">
              <img
                src={require('../../assets/images/home2.jpg')}
                className="block md:hidden h-52 m-auto"
              />
              <p className="text-dark text-lg md:text-2xl py-2 md:py-10 font-UbuntuSemiBold">
                We work as Merchant Traders and provide right products and solutions as per the
                requirement of our clients. We work on a solution based approach and believe in
                providing sustainable and earth friendly products by building green supply chain
                management program.
              </p>
              <img
                src={require('../../assets/images/home2.jpg')}
                className="hidden md:block h-52 m-auto"
              />
            </motion.div>
          </motion.div>
        </div>
        <div className="py-2 md:p-5">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}>
            <motion.div variants={cardVariants} className="grid  md:grid-cols-2">
              <img src={require('../../assets/images/home3.jpg')} className="h-44 m-auto" />
              <p className="text-dark text-lg md:text-2xl py-2 md:py-10 font-UbuntuSemiBold">
                We provide Marketing, Financial and Statutory Compliance services to facilitate the
                growth of your business in domestic and international arena.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
