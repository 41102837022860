import React, { useEffect } from 'react';
import AboutInfo from '../Components/About/AboutInfo';
import { Helmet } from 'react-helmet';

export default function About() {
  useEffect(() => {
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Green Rangers Consulting - About</title>
        <meta name="description" content="About - us" />
      </Helmet>
      <div className="h-full md:pt-12">
        <AboutInfo />
      </div>
    </>
  );
}
