import React, { useEffect } from 'react';
import EnquiryForm from '../Components/ContactUs/EnquiryForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';

export default function Contact() {
  useEffect(() => {
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Green Rangers Consulting - Contact</title>
        <meta name="description" content="Contact Us" />
      </Helmet>
      <div className="">
        <div className="bg-contact md:h-96 bg-cover bg-no-repeat flex justify-between">
          <div>
            <p className="text-light text-3xl md:text-5xl pl-6 py-14 md:py-32 font-UbuntuSemiBold absolute md:relative">
              Contact Us
            </p>
          </div>
          <img src={require('../assets/images/contactus.jpg')} />
        </div>
        <div className="px-6 md:px-16">
          <EnquiryForm />
          <hr />
          <div className="md:flex md:justify-center md:space-x-60 items-center md:px-16 py-10">
            <div className="">
              <p className="text-3xl text-dark font-UbuntuBold">
                Green Rangers Consulting Private Limited
              </p>
              <p className="text-lg md:text-xl text-dark mt-1 font-UbuntuSemiBold">
                1001 G, Phase 1, Godrej City, Panvel, Maharashtra- 410221
              </p>
              <div className="flex justify-start items-center space-x-2">
                <FontAwesomeIcon icon={faPhone} className="h-4 pt-[6px] text-center text-dark" />
                <p className="text-lg md:text-xl text-dark mt-1 font-UbuntuSemiBold">/</p>
                <FontAwesomeIcon icon={faWhatsapp} className="h-6 pt-[6px] text-center text-dark" />
                <p className="text-lg md:text-xl text-dark mt-1 font-UbuntuSemiBold">
                  : +91-8976607939
                </p>
              </div>
              <p className="text-lg md:text-xl text-dark mt-1 font-UbuntuSemiBold">
                Email: info@greenrangersconsulting.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
