import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  return (
    <div className="w-[100%] bg-dark">
      <div className="p-3 md:p-16 md:pt-3 md:pb-3 text-center">
        <img src={require('../../assets/images/logofooter.png')} className="h-28 m-auto" />
        <p className="w-28 md:w-[unset] text-sm mb-4 m-auto text-white font-UbuntuSemiBold">
          Earth-friendly Quality Solutions
        </p>
        <div className="flex justify-between mx-20 md:mx-[unset] md:justify-center md:space-x-4 items-center">
          <a
            href="https://www.linkedin.com/company/green-rangers-consulting-private-limited/"
            className="cursor-pointer"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} className="text-white" />
          </a>
          <a
            href="mailto:info@greenrangersconsulting.com"
            className="cursor-pointer"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon icon={faEnvelope} className="text-white" />
          </a>
          <a
            href="https://twitter.com/eximgreen"
            className="cursor-pointer"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="text-white" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100094380170741"
            className="cursor-pointer"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon icon={faFacebook} className="text-white" />
          </a>
          <a
            href="https://www.instagram.com/greenrangersbanerjee/?hl=en"
            className="cursor-pointer"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="text-white" />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=+918976607939&text=Hello"
            className="cursor-pointer"
            target="_blank"
            rel="noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} className="text-white" />
          </a>
        </div>
        <div className="flex flex-row md:space-x-3 pt-2 text-center md:text-center mx-10 justify-between md:justify-center">
          <Link to="/terms">
            <p className="text-md font-semibold mt-2 text-light w-14 md:w-28 m-auto font-UbuntuRegular">
              Terms
            </p>
          </Link>
          <Link to="/privacy-policy">
            <p className="text-md font-semibold mt-2 text-light w-14 md:w-28 font-UbuntuRegular">
              Privacy Policy
            </p>
          </Link>
          <Link to="/contact-us">
            <p className="text-md font-semibold mt-2 text-light w-14 md:w-28 font-UbuntuRegular">
              Contact Us
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
