import React from 'react';
// import ProductBrief from './ProductBrief';

// const ServiceData = [
//   ['Leather and leather items', 'leather2.jpg', 'leather3.jpg', 'leather1.jpg', '🍅', 340, 10],
//   ['', 'leather4.png', 'leather5.jpg', 'leather6.jpg', '🍅', 340, 10],
//   ['', 'leather8.png', 'leather7.jpg', '', '🍅', 340, 10],
//   ['Rice', 'rice.jpg', '', '', '🍊', 20, 40],
//   ['Millets', 'millets.jpeg', '', '', '🍋', 60, 90],
//   ['Organic Food', 'food1.jpeg', 'food2.jfif', 'food3.jpeg', '🍐', 80, 120]
// ];

export default function ProductInfo() {
  return (
    <div>
      <div className="bg-service md:h-96 bg-cover bg-no-repeat flex justify-between">
        <div>
          <p className="text-light text-3xl md:text-5xl pl-6 py-16 md:py-32 font-UbuntuSemiBold absolute md:relative">
            You Order, We Deliver !
          </p>
        </div>
        <img src={require('../../assets/images/services.jpg')} />
      </div>
      <div className="px-6 md:px-16 border-b-[1px] border-dark pb-10 text-dark">
        <p className="text-center text-dark text-2xl md:text-3xl pt-5 pb-2 md:pb-[unset] md:pt-10 font-UbuntuSemiBold">
          Our Products
        </p>
        <p className="text-center text-dark md:pt-2 md:pb-5 m-auto font-UbuntuSemiBold text-lg">
          We are presently working with Leather Accessories, Agro products, Jute Bags and Textile
          Garments.
        </p>
        <p className="text-center text-2xl pt-5 font-UbuntuSemiBold">Leather Products</p>
        <div className="grid grid-cols-2 md:grid-cols-7">
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/leather1.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/leather2.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/leather3.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/leather4.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/leather5.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/leather6.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/leather7.jpg')} className="m-auto h-48" />
            {/* <p className="text-center text-lg md:text-xl py-5 font-UbuntuSemiBold">
              Leather Product
            </p> */}
          </div>
        </div>
        <p className="text-center text-2xl pt-5 font-UbuntuSemiBold">Agro Products</p>
        <div className="grid grid-cols-2 md:grid-cols-5">
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/rice1.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/rice2.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/millets.jpeg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/food1.jpeg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/food2.jfif')} className="m-auto h-48" />
          </div>
        </div>
        <p className="text-center text-2xl pt-5 font-UbuntuSemiBold">Jute Products</p>
        <div className="grid grid-cols-2 md:grid-cols-6">
          {/* <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/jute1.jpg')} className="m-auto h-48" />
          </div> */}
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/jute2.jpg')} className="m-auto h-48" />
          </div>
          {/* <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/jute3.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/jute4.jpg')} className="m-auto h-48" />
          </div> */}
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/jute5.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/jute6.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/jute7.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/jute8.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/jute9.webp')} className="m-auto h-48" />
          </div>
        </div>
        <p className="text-center text-2xl pt-5 font-UbuntuSemiBold">Garments</p>
        <div className="grid grid-cols-2 md:grid-cols-5">
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/garments1.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/garments2.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/garments3.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/garments4.jpg')} className="m-auto h-48" />
          </div>
          <div className="border-2 border-light rounded-lg shadow-md shadow-gray-300 mt-4 mx-2 md:m-4">
            <img src={require('../../assets/images/garments5.jpg')} className="m-auto h-48" />
          </div>
        </div>

        {/* {ServiceData.map(([title, imgVal1, imgVal2, imgVal3, emoji]) => (
          <ProductBrief
            title={title}
            imgVal1={imgVal1}
            imgVal2={imgVal2}
            imgVal3={imgVal3}
            emoji={emoji}
            key={emoji}
          />
        ))} */}
      </div>
    </div>
  );
}
