import React from 'react';

export default function Credentials() {
  return (
    <div className="px-6 md:px-16 py-10">
      <p className="text-center text-2xl md:text-3xl font-UbuntuSemiBold">Our Credentials</p>
      <p className="text-center text-xl md:text-3xl py-5 font-UbuntuRegular md:mx-28">
        Green Rangers Consulting Private Limited is associated with leading Institutions, under
        Government of India
      </p>
      <div className="grid md:grid-cols-3 md:space-x-5 ">
        <div className="py-5 md:p-10">
          <img
            src={require('../../assets/images/Picture1.png')}
            className="h-20 justify-center m-auto my-2"
          />
          <p className="text-center font-UbuntuSemiBold">
            Registered with Federation of Indian Exports
          </p>
        </div>
        <hr className="md:hidden" />
        <div className="py-5 md:p-10">
          <img
            src={require('../../assets/images/Picture2.png')}
            className="h-20 justify-center m-auto my-2"
          />
          <p className="text-center font-UbuntuSemiBold">
            Registered with Agricultural and Processed Food Products Export Development Agency
          </p>
        </div>
        <hr className="md:hidden" />
        <div className="py-5 md:p-10">
          <img
            src={require('../../assets/images/Picture3.png')}
            className="h-20 justify-center m-auto my-2"
          />
          <p className="text-center font-UbuntuSemiBold">
            Director General of Foreign Trade , Govt of India{' '}
          </p>
        </div>
      </div>
      <p className="text-center font-UbuntuSemiBold">
        GST IN: <span className="text-dark">27AAKCG5000D1Z2</span>
      </p>
      <p className="text-center font-UbuntuSemiBold">
        IMPORT EXPORT CODE: <span className="text-dark">AAKCG5000D</span>
      </p>
    </div>
  );
}
