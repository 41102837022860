import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faBullhorn,
  faHeadset,
  faSackDollar
  // faStickyNote
} from '@fortawesome/free-solid-svg-icons';

export default function ServiceInfo() {
  const cardVariants = {
    offscreen: {
      x: '100%'
    },
    onscreen: {
      x: 0,
      rotate: 0,
      transition: {
        // type: 'spring',
        bounce: 0.4,
        duration: 0.5
      }
    }
  };
  const cardVariants2 = {
    offscreen: {
      x: 0
    },
    onscreen: {
      x: '100%',
      rotate: 0,
      transition: {
        // type: 'spring',
        bounce: 0.4,
        duration: 0.5
      }
    }
  };
  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      className="mx-6 md:mx-10"
      id="bottom">
      <p className="text-center text-dark text-2xl md:text-3xl pt-10 font-UbuntuSemiBold">
        Services
      </p>
      <p className="text-center text-dark mt-2 md:mt-[unset] md:w-[750px] m-auto font-UbuntuSemiBold text-lg">
        Our services are catered towards facilitating growth in your business.
      </p>
      <p className="text-center text-dark md:w-[750px] m-auto font-UbuntuSemiBold text-lg">
        Our Financial Loan and Compliance solutions help to grow your business in international or
        in domestic markets.
      </p>
      <div className="hidden md:block">
        <motion.div
          variants={cardVariants}
          className="w-1/2 border-2 border-light rounded-lg mt-10 my-10 shadow-md shadow-gray-300 text-dark">
          <FontAwesomeIcon
            icon={faSackDollar}
            className="h-24 pt-10 text-center w-[100%] text-dark"
          />
          <p className="text-center text-xl py-5 font-UbuntuSemiBold">Loans and Trade Finance</p>
          <p className="text-center text-lg pb-5 font-UbuntuRegular w-2/3 m-auto">
            All types of Business Loans, Loan against property, Supply Chain Finance, Working
            Capital Loans, Letter of Credit documentation, Bill discounting and Export Credit
          </p>
        </motion.div>
        <motion.div
          variants={cardVariants2}
          className="w-1/2 border-2 border-light rounded-lg my-10 shadow-md shadow-gray-300 text-dark">
          <FontAwesomeIcon icon={faHeadset} className="h-24 pt-10 text-center w-[100%] text-dark" />
          <p className="text-center text-xl py-5 font-UbuntuSemiBold">Compliance Support</p>
          <p className="text-center text-lg pb-5 font-UbuntuRegular w-2/3 m-auto">
            Providing consultancy and advisory to meet your Compliance objectives in International
            Trade
          </p>
        </motion.div>
      </div>
      <div className="md:hidden">
        <div className="border-2 border-light rounded-lg mt-10 my-10 shadow-md shadow-gray-300 text-dark">
          <FontAwesomeIcon
            icon={faSackDollar}
            className="h-24 pt-10 text-center w-[100%] text-dark"
          />
          <p className="text-center text-xl py-5 font-UbuntuSemiBold">Loans and Trade Finance</p>
          <p className="text-center text-lg pb-5 font-UbuntuRegular mx-3 m-auto">
            All types of Business Loans, Loan against property, Supply Chain Finance, Working
            Capital Loans, Letter of Credit documentation, Bill discounting and Export Credit
          </p>
        </div>
        <div className="border-2 border-light rounded-lg my-10 shadow-md shadow-gray-300 text-dark">
          <FontAwesomeIcon icon={faHeadset} className="h-24 pt-10 text-center w-[100%] text-dark" />
          <p className="text-center text-xl py-5 font-UbuntuSemiBold">Compliance Support</p>
          <p className="text-center text-lg pb-5 font-UbuntuRegular mx-3 m-auto">
            Providing consultancy and advisory to meet your Compliance objectives in International
            Trade
          </p>
        </div>
      </div>
      {/* <motion.div
        variants={cardVariants}
        className="w-1/2 border-2 border-light rounded-lg my-10 shadow-md shadow-gray-300">
        <FontAwesomeIcon
          icon={faStickyNote}
          className="h-24 pt-10 text-center w-[100%] text-dark"
        />
        <p className="text-center text-xl py-5 font-UbuntuSemiBold">Content Strategy</p>
        <p className="text-center text-lg pb-5 font-UbuntuRegular w-2/3 m-auto">
          Developing content strategies for enhancing your product reach to international markets
        </p>
      </motion.div> */}
      {/* <motion.div
        variants={cardVariants2}
        className="w-1/2 border-2 border-light rounded-lg mb-10 my-10 shadow-md shadow-gray-300">
        <FontAwesomeIcon icon={faBullhorn} className="h-24 pt-10 text-center w-[100%] text-dark" />
        <p className="text-center text-xl py-5 font-UbuntuSemiBold">Digital Marketing</p>
        <p className="text-center text-lg pb-5 font-UbuntuRegular w-2/3 m-auto">
          Cost effective marketing tools to enhance your brands or product visibility in national or
          international markets
        </p>
      </motion.div> */}
    </motion.div>
  );
}
