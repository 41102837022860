import React from 'react';
import InfoSlide from './InfoSlide';

export default function Intro() {
  return (
    <div className="items-center">
      <InfoSlide />
    </div>
  );
}
