import React from 'react';
import Vision from './Vision';
import Credentials from './Credentials';
// import Message from './Message';
import InfoStart from './InfoStart';

export default function AboutInfo() {
  return (
    <div>
      <div className="bg-intro md:h-96 bg-cover bg-no-repeat flex justify-between">
        <p className="text-light text-3xl md:text-5xl pl-6 py-14 md:py-32 font-UbuntuSemiBold absolute md:relative">
          About Us
        </p>
        <img src={require('../../assets/images/aboutus.jpg')} />
      </div>
      <InfoStart />
      <hr />
      <Vision />
      <hr />
      <Credentials />
      <hr />
      {/* <Message /> */}
    </div>
  );
}
