import React, { useEffect } from 'react';
import ProductInfo from '../Components/Services/ProductInfo';
import ServiceInfo from '../Components/Services/ServiceInfo';
import { Helmet } from 'react-helmet';

export default function Services() {
  useEffect(() => {
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Green Rangers Consulting - Services</title>
        <meta name="description" content="Products & Services" />
      </Helmet>
      <div className="h-full md:pt-12">
        <ProductInfo />
        <ServiceInfo />
      </div>
    </>
  );
}
