import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function Terms() {
  useEffect(() => {
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Green Rangers Consulting - Terms</title>
        <meta name="description" content="Terms" />
      </Helmet>
      <div>
        <p className="bg-gradient-to-r from-dark to-gray-400 w-full p-16 text-center text-light text-3xl md:py-36 md:pb-20 font-UbuntuSemiBold">
          Terms
        </p>
        <div className="p-6 md:p-16">
          <p className="text-md text-dark font-UbuntuSemiBold">
            WITH EFFECT FROM 1st July 2023
            <br />
            Welcome to Green Rangers Consulting!
            <br />
            This is a legal agreement (hereinafter referred to as &quot;Terms of Use&quot;) between
            you (the person accessing, viewing or otherwise using the Platform (as defined
            hereinafter), and hereinafter referred to as &quot;You&quot;, &quot;Your&quot;,
            &quot;Yourself&quot; or &quot;User&quot; as per the context) and GREEN RANGERS
            CONSULTING PRIVATE LIMITED, a company incorporated under the Companies Act, 2013 having
            it&apos;s the registered office at 1001G, Phase 1, Godrej City, Panvel, Maharashtra-
            410221 , or any of its subsidiaries which term shall include its Affiliates, permitted
            assigns and successors (hereinafter referred to as &quot;Green Rangers Consulting&quot;,
            &quot;Company&quot;, &quot;We&quot;,&quot;Our&quot; or &quot;Us&quot;)
            <br />
            The Website shall be referred to as the &quot;Platform&quot;.
            <br />
            These Terms of Use constitute a binding and enforceable legal contract between the
            Company and the User(s) or any end user(s) of the Platform
            <br /> By accessing or using this Platform, You agree to be bound by these Terms of Use,
            Privacy Policy (as defined below), and such other agreements as may be adopted by Us
            from time to time (hereinafter referred to as the &quot;Agreements&quot;)
            <br /> Please read these Agreements carefully before using our Platform, availing any of
            our Services (as defined below) or entering into any Transaction (as defined below) on
            this Platform
            <br /> These Terms of Use apply to your visit to and your use of the Platform to avail
            the Services whether through a computer, a mobile phone or any other device, as well as
            to all information provided by you on the Website
            <br /> By visiting the Platform or by accessing the Website to avail our Services, you
            irrevocably accept and you shall abide by all the obligations stipulated in these Terms
            of Use as well as the Company&apos;s Privacy Policy, both as may
            <br />
            be amended from time to time
            <br />
            <br /> 1. GENERAL <br />
            1.1. If You transact on the Platform, You shall be subject to the Agreements/ that are
            applicable to the Platform for such Transaction. By using the Platform, You shall be
            contracting with Green Rangers Consulting, and these Terms of Use including the
            Agreements constitute Your binding obligations, with Green Rangers Consulting. <br />
            1.2. For the purposes of these Terms of Use, the following terms shall have the meaning
            ascribed to them herein: <br />
            1.2.1.&quot;Personal Information&quot; means any information that relates to a natural
            person which, either directly or indirectly, in combination with other information
            available or likely to be available with a body corporate, is capable of identifying
            such person.
            <br />
            1.2.2.&quot;Privacy Policy&quot; shall mean the privacy policy applicable to the
            Platform and available at here. &quot;Services&quot; means the services provided/
            proposed to be provided by Green Rangers Consulting through the Platform from time to
            time including (i) online digital ledger book / record book wherein the Users, can
            record their ledger entries with their respective customers (ii) allows Users to
            generate a payment link which can be sent to their respective customers, (iii) allowing
            the User to generate invoices and maintain stock register, (iv) allowing the User to
            maintain their staff attendance and salary, (v) allows the User to advertise, sell their
            products to other merchants and retailers (vi) allows retailers and business entities to
            directly sell to consumers (vii) any other services that maybe introduced from
            time-to-time. <br />
            1.2.3.&quot;Transaction&quot; means availing any Services on or through the Platform;{' '}
            <br />
            <br />
            2. ACCEPTANCE OF TERMS By registering for and/or using the Platform in any manner, You
            agree to all of the terms and conditions contained herein, which also incorporate the
            Privacy Policy and all other operating rules, policies and procedures that may be
            published from time to time by Green Rangers Consulting, each of which is incorporated
            herein by reference and each of which may be updated by Green Rangers Consulting from
            time to time without notice to You in accordance with the terms set out under the
            &quot;Modification of Terms of Use&quot; section below. In addition, some Services
            offered through the Platform may be subject to additional terms and conditions specified
            by Green Rangers Consulting from time to time; Your continued use of such services is
            subject to those additional terms and conditions, which are incorporated into these
            Terms of Use by this reference. These Terms of Use apply to all Users and visitors of
            the Platform. If the User does not agree to any of these Terms of Use or comply with the
            requirements herein, the user is requested to cease using and visiting the Platform or
            use the Services. <br />
            <br />
            3. SIGNING UP AND ON-BOARDING <br />
            3.1. A User is required to create a profile/sign-up on the Platform
            (&quot;Profile&quot;) using phone number among other details to use/peruse the Services
            of the Platform. In addition to setting up username and password to create the Profile,
            the User will be required to furnish certain details, including but not limited to phone
            numbers and details of its customers and businesses. The User represents, warrants and
            covenants that the information for Profile creation provided by the User shall be
            accurate, truthful, and complete (including, but not limited to Your name
            (&quot;Username&quot;), e-mail address and a password) and that You shall keep Your
            registration information accurate and up-to-date. Failure to do so shall constitute a
            breach of these Terms of Use, which may result in termination of the Profile on the
            Platform and denial of Services, at the discretion of Green Rangers Consulting.
            <br /> 3.2. Registration as a User and creation of Profile is only a one-time process
            and if the User has been previously registered, the User shall login / sign into the
            Profile using the same credentials as provided during the registration process. <br />
            3.3. The User shall: <br />
            3.3.1.Not provide any false Personal Information to Green Rangers Consulting or create
            any account for anyone other than him/herself without such person&apos;s permission;{' '}
            <br />
            3.3.2.Not use a username that is the name of another person with the intent to
            impersonate that person; <br />
            3.3.3.Not use a username or Profile that is subject to any rights of a person other than
            the User without appropriate authorization; or (d) use a username that is a name that is
            otherwise offensive, vulgar, obscene or otherwise unlawful; <br />
            3.3.4.immediately notify Green Rangers Consulting in writing of any unauthorized use of
            Your account, or other account related security breach of which the User becomes aware;
            <br /> 3.4. The User represents and warrants that the User is an Indian resident and if
            the User is an individual, the User is of legal age to form a binding contract, or that
            if the User is registering on behalf of an entity, that the User is authorized to enter
            into, and bind the entity to, these Terms of Use and register on the Platform. Access to
            the Platform is not available to individuals who are younger than 18 (eighteen) years
            old. Green Rangers Consulting may, in its sole discretion, refuse to offer the access to
            the Platform to any person or entity and change its eligibility criteria at any time.
            The User is solely responsible for ensuring that these Terms of Use are in compliance
            with all laws, rules and regulations applicable to the User and the right to access the
            Platform is revoked where these Terms of Use or use of the Platform is prohibited and,
            in such circumstances, the User agrees not to use or access the Platform in any way.
            <br /> 3.5. The User agrees to receive communications from the Company regarding: (i)
            information relating to transactions recorded on the Platform; (ii) requests for
            payment; (iii) new product launches and offers for the user (iv) information about the
            Company and the Services; (v) promotional offers and services from the Company and its
            third-party partners, and (vi) local and international news of interest (vi) any other
            matter in relation to the Services.
            <br /> 3.6. The User further agrees and undertakes to promptly update its details on the
            Platform in the event of any change or modification of such details. The User is solely
            responsible for maintaining the security and confidentiality of its username and
            password and agrees to immediately notify the Company in writing at
            info@greenrangersconsulting.com of any unauthorized use of its Profile or any other
            breach of security. The User expressly agrees to be liable and accountable for all
            activities that take place through its Profile in furtherance of the use of Service or
            otherwise. The Company expressly excludes any liability for any unauthorized access to a
            User&apos;s Profile.
            <br /> <br />
            4. KNOW YOUR CUSTOMER POLICY Users may be required to upload certain information and
            documents to ascertain their eligibility to use certain features and functionalities of
            the Services including but not limited to their identification documents (&quot;KYC
            Documents&quot;). The User agrees and warrants to provide valid, true, complete, and
            up-to-date KYC Documents and any other document that maybe necessary for accessing
            certain services on the platform. The User hereby authorizes the Company and the
            third-party service providers engaged with or by the Company, or interact with in
            connection with using the Platform to process KYC Documents and ascertain the
            User&apos;s eligibility. It is hereby clarified the permission of use and processing of
            the KYC Documents by a third-party service provider is concerned, the same shall be
            governed by the relevant policies of such third-party service provider. The User further
            acknowledges that in an event of providing an incorrect or misleading information, the
            act shall constitute a material breach of these Terms, and the User&apos;s access to
            certain features of the Services may be limited or denied in such event.
            <br /> <br />
            5. USER RESPONSIBILITIES
            <br /> 5.1. The Company does not accept any responsibility or liability for any loss or
            damage the User may suffer or incur if any information, documentation, material, or data
            provided to avail the Services is incorrect, incomplete, inaccurate, or misleading, or
            if the User fails to disclose any material fact. <br />
            5.2. The User hereby represents and warrants that all information that is provided by
            the User through or in relation to the Services is valid, complete, true, and correct on
            the date of acceptance of the Terms and the user shall update the documents if there is
            any change, so the documents continue to be valid, complete, true, and correct
            throughout the duration of the User&apos;s use of the Platform. <br />
            5.3. The User shall be solely responsible for ensuring compliance with applicable laws
            and shall be solely liable for any liability that may arise due to a breach of its
            obligations in this regard. <br />
            5.4. While the Company uses commercially reasonable efforts to provide Users with a
            daily backup of their Transaction Information, Users should regularly and independently
            save, backup, and archive such Transaction Information. <br />
            5.5. By using the Platform to avail any Services, the User agrees that the User is
            involved in the transmission of sensitive and Personal Information, including but not
            limited to registration information and mobile number (collectively &quot;SPI&quot;),
            which falls within the ambit of the Information Technology (Reasonable Security
            Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011. Green
            Rangers Consulting shall take necessary steps to secure and maintain the confidentiality
            of the SPI that is transmitted in compliance with the Privacy Policy, the Information
            Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or
            Information) Rules, 2011 and Information Technology (Security of Prepaid Payment
            Instruments) Rules, 2017 and other applicable laws. <br />
            5.6. Green Rangers Consulting disclaims any responsibility for any harm resulting from
            anyone&apos;s use, viewing, or downloading of Third-Party Content. If You access or use
            any Third-Party Content, You are responsible for taking precautions as necessary to
            protect Yourself and Your computer systems from viruses, worms, Trojan horses, and other
            harmful or destructive content. Any Third-Party Content offered through or available on
            the Platform is the Third-Party Content provider or Third Party Content creator&apos;s
            (the person, persons or entity that was responsible for uploading, adding or sharing the
            content or materials) sole responsibility, and You agree that Green Rangers Consulting
            shall not be liable for any damages that may result from Your use of the Third-Party
            Content. <br />
            5.7. The User shall use the Services in any manner as permitted in these Terms. The User
            may not: • use the Platform for any purpose that is prohibited by these Terms of Use or
            other Agreements. The User is responsible for all of its activity in connection with,
            and use of the Platform. Additionally, User shall abide by all applicable local, state,
            national and international laws and regulations and, if User represents a business, any
            advertising, marketing, privacy, or other self-regulatory code(s) applicable to Your
            industry. • use the Services to transmit any data or send or upload any material that
            contains viruses, or any other harmful programmes or similar computer code designed to
            adversely affect the operation of any mobile, computer software or hardware, or any
            other device; • use any robot, any other automated process or device, or manual process
            to monitor or copy the Platform or Services or any portion thereof; • Infringe either
            directly or indirectly any third-party&apos;s rights, including but not limited to
            copyrights, patents, trademarks, or trade secrets, right of publicity or other right of
            any other person or entity or violates any law or contractual duty or obligations of
            confidentiality;; • use the Services in furtherance of / to engage in any activity or
            publish content which may be grossly harmful, harassing, blasphemous, defamatory,
            obscene, pornographic, paedophilic, libellous, invasive of another&apos;s privacy,
            hateful, or racially, ethnically objectionable, disparaging, relating or encouraging
            money laundering or gambling, or otherwise unlawful in any manner whatever; or
            unlawfully threatening or unlawfully harassing including but not limited to
            &quot;indecent representation of women&quot; within the meaning of the Indecent
            Representation of Women (Prohibition) Act, 1986; • violate applicable laws in any manner
            in its use of the Platform. • users are expected to ensure that the goods and services
            they send payment links for are in compliance with all applicable laws; generating
            payment link for prohibited content may result in the suspension or removal of
            user&apos;s account. The User shall not use the Services for any sale or supply of
            prohibited products or services as notified by the laws of the land. <br />
            5.8. The User shall be solely responsible for compliance with all the applicable laws
            including without limitation the Prevention of Money Laundering Act, 2002 and the rules
            made thereunder. The Company shall not be responsible for any claims or liability or
            losses that may arise due to noncompliance of the anti-money laundering laws in India.
            <br />
            5.9. The User shall not (directly or indirectly):
            <br /> 5.9.1.take any action that imposes or may impose (as determined by Green Rangers
            Consulting in its sole discretion) an unreasonable or disproportionately large load on
            Green Rangers Consulting (or its third party providers&apos;) infrastructure;
            <br /> 5.9.2.interfere or attempt to interfere with the proper working of the Platform
            or any activities conducted on the Platform or bypass any measures Green Rangers
            Consulting may use to prevent or restrict access to the Platform (or other accounts,
            computer systems or networks connected to the Platform); <br />
            5.9.3.run any form of auto-responder or ‘spam&apos; on the Platform, use manual or
            automated software, devices, or other processes to ‘crawl&apos; or ‘spider&apos; any
            part of the Platform, or harvest or scrape any content (including but not limited to
            Third-Party Content) from the Platform; <br />
            5.9.4.modify, adapt, appropriate, reproduce, distribute, translate, create derivative
            works or adaptations of, publicly display, republish, repurpose, sell, trade, or in any
            way exploit the Platform or content (including but not limited to Third-Party Content),
            except as expressly authorized by Green Rangers Consulting;
            <br /> 5.9.5.decipher, decompile, disassemble, reverse engineer or otherwise attempt to
            derive any hardware, or source code or underlying ideas or algorithms of any part of the
            Service (including without limitation any application or widget), except to the limited
            extent applicable laws specifically prohibit such restriction;
            <br /> 5.9.6.copy, rent, lease, distribute, or otherwise transfer any of the rights that
            You receive hereunder; <br />
            5.9.7.delete or modify any content of the Platform, including but not limited to, legal
            notices, disclaimers or proprietary notices such as copyright or trademark symbols,
            logos, that You do not own or have express permission to modify;
            <br />
            5.9.8.use the Platform in any unlawful manner, for any unlawful purpose, or in any
            manner inconsistent with these Terms of Use, or act fraudulently or maliciously, for
            example, by hacking into or inserting malicious code, including viruses, or harmful
            data, onto the Platform or any operating system;
            <br /> 5.9.9.make false or malicious statements against the Platform or Green Rangers
            Consulting; or <br />
            5.9.10. otherwise take any action in violation of Green Rangers Consulting&apos;s
            guidelines and policies. <br />
            <br />
            6. TRANSACTION <br />
            6.1. The Users may upload information relating to transactions with their customers or
            relating to their businesses, including the sale of goods or services, costs, amounts
            paid and payable, and details of goods and services, on the Platform (such information
            is referred to as &quot;Transaction Information&quot;). Transaction Information may be
            exchanged between the Users and their customers through telephonic calls, text message,
            email, or other electronic mediums that would depend on the contact details of Users and
            their customers provided on the Platform. <br />
            6.2. The User shall be solely responsible for obtaining consent from its customers and
            the Company shall assume that such consent as required is received by the User, if the
            User provides details of such Transaction Information relating to any of such customers
            at any time during the use of the Platform. <br />
            6.3. The user before uploading any customer information should undertake the following:
            <br /> 1. Before creating or uploading the first Transaction Information with respect to
            user&apos;s customers, the User shall inform such customers of its use of the Platform
            to record such Transaction Information and Transaction Information related to future
            transactions and seek such customer&apos;s express consent in this regard to create the
            profile of the customer on the Platform, which will require sharing such customer&apos;s
            phone number and contact details with the Company; <br />
            2. With this approval the customers of the user may receive communications from the
            Company regarding but not limited to: (a) information relating to their transactions
            recorded on the Platform; (b) requests for payment; (c) information about the Company
            and the Services; (d) promotional offers and services from the Company and its
            third-party partners, and (e) any other matter in relation to the Services. <br />
            3. If such customers fail to provide consent, or withdraw consent, the User shall
            immediately cease to use the Services in relation to such customers. <br />
            <br />
            7. INTELLECTUAL PROPERTY
            <br /> 7.1. All rights, title, and interest in and to the Platform and Services,
            including all intellectual property rights arising out of the Platform and Services, are
            owned by or otherwise lawfully licensed by the Company. You shall abide by and maintain
            all copyright notices, information, and restrictions contained in any such content
            provided by Green Rangers Consulting accessed through the Platform.
            <br /> 7.2. Subject to these Terms of Use, the Company permits the User a non-exclusive,
            non-transferable, revocable, and limited licence to use the Platform and Services in
            accordance with these Terms and its written instructions issued from time to time.
            <br /> 7.2.1.The User should assume that everything the User sees or reads on the
            Platform is protected under the Indian Copyright Act, 1957 and other intellectual
            property laws of India and may not be used except with the prior written permission of
            the Company.
            <br />
            7.2.2.The Company may freely use, copy, disclose, publish, display, distribute without
            any payment of royalty, acknowledgement, prior consent, or any other form of restriction
            arising out of the User&apos;s intellectual property rights. The contents of this
            Platform, including but not limited to the text and images herein and their
            arrangements, unless otherwise noted, are copyright-protected in the whole and every
            part of this Platform and the same belongs to the Company and may not be used, sold,
            licensed, copied or reproduced in whole or in part in any manner or form or in or on any
            media to any person without the prior written consent of the Company. Use, reproduction,
            distribution or storage of any content provided by Green Rangers Consulting other than
            for personal, non-commercial use is expressly prohibited without prior written
            permission from Green Rangers Consulting, or from the copyright holder identified in the
            copyright notice of the content provided by Green Rangers Consulting, as applicable.
            User shall not sell, license, rent, or otherwise use or exploit any content provided by
            Green Rangers Consulting(i) for commercial (whether or not for profit) use, without the
            prior written consent of Green Rangers Consulting, or (ii) in any way that violates any
            third party right.
            <br /> 7.3. Except as stated in these Terms, nothing in these Terms should be construed
            as conferring any right in or license to the Company&apos;s or any third party&apos;s
            intellectual rights. <br />
            7.4. The User Must: <br />
            7.4.1.not copy, transmit, display, perform, distribute (for compensation or otherwise),
            license, alter, store or otherwise use the Platform or any of its components;
            <br /> 7.4.2.not delete or modify any content of the Platform, including but not limited
            to, legal notices, disclaimers or proprietary notices such as copyright or trademark
            symbols, logos, that You do not own or have express permission to modify; <br />
            7.4.3.not make false or malicious statements against the Platform, allied services or
            Green Rangers Consulting;
            <br />
            7.4.4.not use the Platform in any unlawful manner, for any unlawful purpose, or in any
            manner inconsistent with these Terms of Use, or act fraudulently or maliciously, for
            example, by hacking into or inserting malicious code, including viruses, or harmful
            data, into the Platform or any operating system; <br />
            7.4.5.not engage in any form of antisocial, disrupting, or destructive acts, including
            ‘flaming&apos;, ‘Spamming&apos;, ‘flooding&apos;, ‘trolling&apos;, ‘phishing&apos; and
            ‘griefing&apos; as those terms are commonly understood and used on the internet; <br />
            7.4.6.not use the Platform or any allied services in a way that could damage, disable,
            overburden, impair or compromise the Platform, our systems or security or interfere with
            other Users; <br />
            7.4.7.not carry out reverse engineering in respect of the hardware or software
            underlying the Platform or any part thereof; and <br />
            7.4.8.comply with any applicable law and regulation relating to downloading, using or
            otherwise exporting the technology used or supported by the Platform or allied services,
            which may include but is not limited to not using the Platform in a country where the
            use of the Platform or any allied service is unlawful. <br />
            7.5. All content provided by Green Rangers Consulting is made available as is and
            without any representations or warranties whatsoever, and Green Rangers Consulting
            hereby disclaims any implied warranties, including but not limited to warranties of
            fitness for use, accuracy, merchantability, and non-infringement. <br />
            <br />
            8. THIRD PARTY CONTENT AND SERVICES
            <br />
            8.1. The Services may include services, documents, and information owned by, licensed
            to, or otherwise made available by a third party (&quot;Third Party Services&quot;) or
            contain links to Third Party Services. Users understand that Third Party Services are
            the responsibility of the third party that created or provided it and acknowledge that
            use of such Third-Party Services is solely at their own risk.
            <br /> 8.2. The Company makes no representations and hereby expressly excludes all
            warranties and liabilities arising out of or pertaining to such Third-Party Services,
            including their accuracy or completeness. Further, all intellectual property rights in
            and to Third Party Services are the property of the respective third parties.
            <br /> 8.3. The Company enables payments via payment service providers partners and the
            User should take care not to share his personal UPI pin or OTP with any third party
            intentionally or unintentionally. The Company never solicits information such as UPI pin
            or OTP over a call or otherwise. <br />
            8.4. The Company shall not be liable for any fraud due to the sharing of such details by
            the User. The providers providing Third Party Services / PSP partners shall not be
            liable for any fraud due to sharing of such details by the User. <br />
            8.5. All Third-Party Content on the Platform, whether publicly posted or privately
            transmitted, is the sole responsibility of the person who originated such Content. Green
            Rangers Consulting cannot guarantee the authenticity of any Third-Party Content or data
            which Users may provide about themselves. The User acknowledges that all Third-Party
            Content accessed using the Platform is at User&apos;s own risk and User will be solely
            responsible and liable for any damage or loss to the User or any other party resulting
            therefrom and Green Rangers Consulting shall have no liability towards You or any other
            party for any damage or loss resulting from such Third-Party Content. <br />
            8.6. For purposes of these Terms of Use, the term &quot;Third-Party Content&quot;
            includes, without limitation, any location information, &quot;floats&quot;, logos,
            images, videos, audio clips, comments, information, data, text, photographs, software,
            scripts, graphics, and interactive features generated, provided, or otherwise made
            accessible by Green Rangers Consulting on or through the Platform.
            <br />
            <br /> 9. TERM AND TERMINATION <br />
            9.1. These Terms of Use shall remain in effect unless terminated in accordance with the
            terms hereunder. <br />
            9.1.1.The Company may terminate a User&apos;s access to or use of the Services, or any
            portion thereof, immediately and at any point, at its sole discretion, if the User
            violates or breaches any of its obligations, responsibilities, or covenants under these
            Terms or the Agreements. <br />
            9.1.2.If User wishes to terminate Profile, User may do so by (i) following the
            instructions on the Platform or (ii) contacting Green Rangers Consulting. <br />
            9.2. All provisions of these Terms of Use which by their nature should survive, or are
            expressly stated as surviving, termination shall survive termination of these Terms of
            Use, including, without limitation, ownership provisions, warranty disclaimers,
            indemnity and limitations of liability. <br />
            9.3. Notwithstanding anything to the contrary contained in the Terms, upon termination
            of a User&apos;s access to or use of the Services, all amounts or outstanding payment
            due by user in relation to user use of or access to the Services, if any, shall become
            immediately payable. <br />
            <br />
            10. COOKIES <br />
            10.1. We employ the use of cookies. By accessing the platform, you agreed to use cookies
            in agreement with the platform&apos;s Privacy Policy. <br />
            10.2. Most interactive websites use cookies to let us retrieve the user&apos;s details
            for each visit. Cookies are used by our website to enable the functionality of certain
            areas to make it easier for people visiting our website. Some of our
            affiliate/advertising partners may also use cookies. <br />
            <br />
            11. DISCLAIMERS AND WARRANTIES
            <br /> 11.1. The use of the Services is at User&apos;s sole risk.
            <br /> 11.2. User acknowledge and agree that the Company is not engaged in the
            provision, grant, or disbursement of any financial product. The Company is not and will
            not be responsible for any claim or for any damages suffered, whether by the Users, the
            customers of the Users or any other person or party, that are related, directly or
            indirectly, to or arise out of the same including any payments made by the User or by
            the customers of the User using the payment link generated using the Platform. The User
            further agrees and undertakes to retain proof of sale documentation (in electronic or
            physical form) in connection with each payment link it generates or sends to customers.{' '}
            <br />
            11.3. To the extent permitted by applicable law, the Services are provided on an
            &quot;as is&quot; and &quot;as available&quot; basis and are provided without any
            representations or warranties of any kind, express or implied, including, but not
            limited to, the implied warranties of title, non-infringement, merchantability and
            fitness for a particular purpose, and any warranties implied by any course of
            performance or usage of trade, all of which are expressly disclaimed, save to the extent
            required by applicable law. The Company does not warrant that operation of the Services
            will be uninterrupted or error free or that the functions contained in the Services will
            meet user requirements. <br />
            11.4. To the fullest extent permissible under applicable law, the Company expressly
            disclaims all warranties of any kind, express or implied, arising out of the Services,
            including warranties of merchantability, fitness for a particular purpose, satisfactory
            quality, accuracy, title and noninfringement, compatibility, applicability, usability,
            appropriateness, and any warranty that may arise out of course of performance, course of
            dealing, or usage of trade. <br />
            11.5. User hereby accept full responsibility for any consequences that may arise from
            user use of the Services, and expressly agree and acknowledge that the Company shall
            have absolutely no liability with respect to the same. <br />
            11.6. To the fullest extent permissible by law, the Company, its affiliates, directors,
            employees and agents and its related parties each disclaim all liability to user for any
            loss or damage arising out of or due to: • user use of, inability to use, or
            availability or unavailability of the Services, including any Third-Party Services; •
            the occurrence or existence of any defect, interruption, or delays in the operation or
            transmission of information to, from, or through the Services, communications failure,
            theft, destruction or unauthorized access to the Company&apos;s records, programmes,
            services, server, or other infrastructure relating to the Services; or • the failure of
            the Services to remain operational for any period of time. <br />
            11.7. Notwithstanding anything to the contrary contained herein, neither the Company nor
            any of its affiliates or related parties shall have any liability to user or any third
            party for any indirect, incidental, special or consequential damages or any loss of
            revenue or profits arising under, directly or indirectly, or relating, in any manner
            whatsoever, to these Terms or the Services. To the maximum extent permitted by law, user
            agree to waive, release, discharge, and hold harmless the Company, its affiliated and
            subsidiary companies, its parent companies, and each of their directors, officers,
            employees, and agents, from any and all claims, losses, damages, liabilities, expenses
            and causes of action arising out of the Services.
            <br /> 11.8. Green Rangers Consulting currently is not a payment aggregator or payment
            gateway-it is enabling payments for its users via third-party payments service
            providers. Green Rangers Consulting may levy charges for the usage of service. In case
            user needs an invoice please email to info@greenrangersconsulting.com. <br />
            11.9. All liability of Green Rangers Consulting, its shareholders, affiliates,
            directors, employees, agents, representatives, partners, suppliers or content providers
            howsoever arising for any loss suffered as a result of Your use of the Platform or
            content (including but not limited to Third-Party Content) is expressly excluded to the
            fullest extent permitted by applicable law, save that, if a court of competent
            jurisdiction determines that liability of Green Rangers Consulting, its affiliates,
            directors, employees, agents, representatives, partners, suppliers has arisen, the total
            of such liability shall be limited in aggregate to One Thousand Rupees (INR 1000).{' '}
            <br />
            11.10. Green Rangers Consulting will not be liable or responsible for: <br />
            11.10.1. Any instance where the transaction is unable to be completed or does not
            materialize; <br />
            11.10.2. any failure to perform, or delay in performance of, any of Green Rangers
            Consulting&apos; obligations under these Terms of Use that is caused by any act or event
            beyond Green Rangers Consulting&apos;s reasonable control, including force majeure
            events; (c) any failure to perform, or delay in performance of, any of Green Rangers
            Consulting&apos;s obligations under these Terms of Use or any financial loss that is
            caused by an assigned partner(s) or third-party service;(d) the Platform not meeting
            Your individual requirements or the Platform containing defects or errors, as the
            Platform has not been developed specifically for You. It is Your responsibility to
            ensure that You use the Platform if the facilities and functions of the Platform meet
            Your requirements;(e) any loss or damage caused by a distributed denial-of-service,
            viruses attack, or other technologically harmful material that may infect Your device,
            data or other proprietary material due to Your use of the Platform. <br />
            11.11. Additionally, by using the Platform and allied services, You acknowledge and
            agree that internet transmissions are never completely private or secure. You understand
            that any message or information You send using the Platform or any allied services may
            be read or intercepted by others, even if there is a special notice that a particular
            transmission is encrypted. <br />
            11.12. To the maximum extent permitted by applicable law, in no event shall Green
            Rangers Consulting, nor its affiliates, directors, employees, agents, representatives,
            partners, suppliers or content providers, be liable under contract, tort, strict
            liability, negligence or any other legal or equitable theory or otherwise (and whether
            or not Green Rangers Consulting, its affiliates, directors, employees, agents,
            representatives, partners, suppliers or content providers had prior knowledge of the
            circumstances giving rise to such loss or damage) with respect to the Platform, allied
            services or content (including but not limited to Third-Party Content) for: (a) the use
            or the inability to use the Platform; (b) indirect, remote or consequential losses or
            damages; (c) loss of actual or anticipated profits; (d) loss of revenue; (e) loss of
            goodwill; (f) unauthorized access to or alteration of Your transmissions or loss of
            data; (g) loss of anticipated savings; (h) wasted expenditure; (i) cost of procurement
            of substitute goods or services; or (j) any other matter relating to the Platform,
            content or allied service. <br />
            11.13. Without prejudice to the foregoing, none of the affiliates, directors, employees,
            agents, representatives, partners, suppliers or content providers of Green Rangers
            Consulting shall be personally liable for any action in connection with the Platform or
            allied services. <br />
            <br />
            12. INDEMNITY User shall indemnify, defend at the Company&apos;s option, and hold
            harmless, the Company, its parent companies, subsidiaries, affiliates, and their
            officers, associates successors, assigns, licensors, employees, directors, agents, and
            representatives, from and against any claim, action, demand, lawsuits, judicial
            proceeding, losses, liabilities, damages and costs (including, without limitation, from
            all damages, liabilities, settlements, costs and attorneys&apos; fees) due to or arising
            out of User&apos;s use or misuse of, or access to, the Platform, or otherwise, violation
            of these Terms of Use or any infringement by the User or any third party who may use
            user account with the Company, of these Terms or the rights of any other person. Green
            Rangers Consulting reserves the right to assume the exclusive defence and control of any
            matter otherwise subject to indemnification by You, in which event You will assist and
            cooperate with Green Rangers Consultingin asserting any available defences. <br />
            <br />
            13. MAINTENANCE OF RECORDS User shall maintain the records of all payment transactions
            on the Platform independently of the Platform (by way physical copies or any other form
            as may deem fit) and the Company reserves the right to seek copies of such records for
            their own use including record keeping. <br />
            <br />
            14. FEES/CHARGES The Company reserves the right to charge convenience fee for the
            Services and non-payment may result in denial of Services. <br />
            <br />
            15. MODIFICATION The Company reserves the right at its sole discretion at any time to
            add, modify or discontinue, temporarily or permanently, the Services (or any part
            thereof) and Terms of Use or allied services (including without limitation, the
            availability of any feature, database, or content) with or without cause. The Company
            shall not be liable for any such addition, modification, suspension or discontinuation
            of the Services. <br />
            <br />
            16. ELECTRONIC RECORD This document is an electronic record in terms of the Information
            Technology Act, 2000 (&quot;IT Act&quot;) and rules made thereunder as may be
            applicable, and the amended provisions pertaining to electronic records in various
            statutes as amended by the IT Act. This electronic record is generated by a computer
            system and does not require any physical or digital signatures. This document is
            published in accordance with the provisions of the IT Act and the rules made thereunder
            that require publishing the rules and regulations, Privacy Policy and Terms of Use of
            the Platform. <br />
            <br />
            17. JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION These Terms of Use shall be
            governed by and construed and enforced in accordance with the laws of India. Subject to
            other provisions in this Clause, courts in Mumbai shall have exclusive jurisdiction over
            all issues arising out of these Terms of Use or the use of the Services. Any
            controversies, conflicts, disputes, or differences arising out of these Terms shall be
            resolved by arbitration in Mumbai in accordance with the Arbitration and Conciliation
            Act, 1996. The tribunal shall consist of 1 (one) arbitrator each appointed by the
            Company and the user. Both the arbitrators will mutually appoint the 3rd arbitrator. The
            language of the arbitration shall be English. The parties to the arbitration shall keep
            the arbitration confidential and not disclose to any person, other than on a need to
            basis or to legal advisors, unless required to do so by law. The decision of the
            arbitrator shall be final and binding on all the Parties hereto. Each party to the
            arbitration shall bear its own costs with respect to any dispute.
            <br /> <br />
            18. CONSENT TO USE DATA <br />
            1. User agree that the Company and any third-party service providers it engages, may, in
            accordance with its Privacy Policy, collect and use user information and technical data
            and related information. <br />
            2. The Company may use information and data pertaining to user use of the Services for
            analytics, trends&apos; identification, and purposes of statistics to further enhance
            the effectiveness and efficiency of the Platform.
            <br /> 3. Subject to applicable laws, the Company may be directed by law enforcement
            agencies or the government and related bodies to disclose data in relation to Users in
            connection with criminal proceedings. User understand and agree that in such instances,
            the Company shall have the right to share such data with relevant agencies or bodies.
            <br /> <br />
            19. REFUND/CANCELLATION Cancellation • UPI Transactions cannot be cancelled after
            initiating the transaction. If the User has entered the wrong number/VPA ID, then the
            Company is not a liable party in the transaction. • All UPI transactions are real-time
            and if there is any delay in the end of the transaction then the User can check with
            support team of Green Rangers Consulting at info@greenrangersconsulting.com after 1 hour
            of the success of the transaction or with the 3rd party through which the UPI
            transaction was initiated. UPI transaction cannot be cancelled once initiated. Green
            Rangers Consulting does not take the ownership of refund of any UPI transaction made by
            the user. Debit/Credit Card • Currently, the Company have not enabled Debit/ Credit Card
            transactions in Green Rangers Consulting app. The Company will update the terms of
            services for this section when the Company enable Debit/ Credit Card transactions.{' '}
            <br />
            <br /> 20. MISCELLANEOUS PROVISIONS <br />
            1. Modification – The Company reserves the right at any time to modify these Terms and
            to add new or additional terms or conditions on use of the Services. Such modifications
            and additional terms and conditions will be communicated to user and, unless expressly
            rejected (in which these Terms shall terminate), will be effective immediately and will
            be incorporated into these Terms. In the event user refuse to accept such changes, these
            Terms will terminate. <br />
            2. Severability - If any provision of these Terms is determined by any court or other
            competent authority to be unlawful or unenforceable, the other provisions of these Terms
            will continue in effect. If any unlawful or unenforceable provision would be lawful or
            enforceable if part of it were deleted, that part will be deemed to be deleted, and the
            rest of the provision will continue in effect (unless that would contradict the clear
            intention of the clause, in which case the entirety of the relevant provision will be
            deemed to be deleted). <br />
            3. Notices - All notices, requests, demands, and determinations for the Company under
            these Terms (other than routine operational communications) shall be sent to
            info@greenrangersconsulting.com <br />
            4. Assignment - These Terms of Use are personal to You, and are not assignable,
            transferable or sublicensable by You except with Green Rangers Consulting prior written
            consent. Green Rangers Consulting may assign, transfer or delegate any of its rights and
            obligations hereunder without Your consent. No agency, partnership, joint venture, or
            employment relationship is created as a result of these Terms of Use and neither party
            has any authority of any kind to bind the other in any respect. <br />
            5. Unless otherwise specified in these Term of Use, all notices under these Terms of Use
            will be in writing and will be deemed to have been duly given when received, if
            personally delivered or sent by certified or registered mail, return receipt requested;
            when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or the
            day after it is sent, if sent for next day delivery by recognized overnight delivery
            service.
            <br /> 6. In respect of these Terms of Use and Your use of this Platform, allied
            service, or content, nothing in these Terms of Use shall be deemed to grant any rights
            or benefits to any person, other than Us and You (and Green Rangers Consulting&apos;s
            and Your respective successors in title or assignees), or entitle any third party to
            enforce any provision hereof, and Green Rangers Consulting and You agree that Green
            Rangers Consulting does not intend that any provision of these Terms of Use should be
            enforceable by a third party by virtue of the Indian Contract Act, 1872 or other
            applicable laws. <br />
            <br />
            21. CONTACT
            <br />
            21.1. In accordance with the Information Technology Act, 2000 and rules made there under
            and other applicable laws, the name and contact details of the Grievance Officer are
            provided below: You may write to the Grievance Officer at the following address: 1001G,
            Phase 1, Godrej City, Panvel, Maharashtra- 410221Email:info@greenrangersconsulting.com
            Phone Number: +91 8976607939
            <br /> 21.2. Please contact Grievance Officer for any questions or comments (including
            all inquiries related to copyright infringement) regarding the Platform. <br />
            21.3. Except where required by applicable law, Green Rangers Consulting cannot ensure a
            response to questions or comments regarding topics unrelated to the terms of this Terms
            of Use or Green Rangers Consulting&apos;s privacy practices. These Terms also include
            the Company&apos;s privacy policy, available under &quot;Privacy Policy&quot; section
            and any internal guidelines, supplementary terms, policies, or disclaimers made
            available or issued from time to time. By continuing to access or use the Platform, or
            any Service on the Platform, user signify acceptance of the Terms. Further, user
            understand that the Platform is intended for the use of Indian residents only and by
            continuing access and/use of the Platform shall be construed as a deemed declaration
            that user is an Indian resident. The Company reserves the right to make changes to these
            Terms by posting the new/updated version and user continued use and/or non-deletion of
            the Platform shall indicate user agreement to such changes. Accordingly, the Company
            encourages user to kindly continue to review the Terms whenever accessing or using the
            Platform so as to be abreast with the changes that we may be carrying out to these
            Terms.
          </p>
        </div>
      </div>
    </>
  );
}
