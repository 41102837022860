import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function Sustainability() {
  useEffect(() => {
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Green Rangers Consulting - Sustainability</title>
        <meta name="description" content="Sustainability" />
      </Helmet>
      <div>
        <div className="bg-sustainability md:h-96 bg-cover bg-no-repeat flex justify-between">
          <div>
            <p className="text-light text-3xl md:text-5xl pl-6 py-14 md:py-32 font-UbuntuSemiBold absolute md:relative">
              Sustainability
            </p>
          </div>
          <img src={require('../assets/images/sustainability.jpg')} />
        </div>

        <div className="px-6 md:px-20">
          <p className="text-center text-dark mt-10 text-3xl font-UbuntuSemiBold">Sustainability</p>
          <p className="text-dark text-xl md:text-2xl py-10 font-UbuntuSemiBold text-center">
            We&apos;re bringing a new level of ambition to our Sustainability Strategy, which is
            structured around four pillars. They are Product, Environment, Partners and Community.
          </p>

          <img src={require('../assets/images/product.png')} className="h-40 m-auto" />
          <p className="text-dark text-xl md:text-2xl pt-2 font-UbuntuSemiBold text-center">
            Product
          </p>
          <p className="text-dark text-xl md:text-2xl pb-2 font-UbuntuRegular text-center">
            We will increase the use of certified, sustainable materials in our products year on
            year.
          </p>
          <hr />

          <img src={require('../assets/images/environment.png')} className="h-40 m-auto mt-10" />
          <p className="text-dark text-xl md:text-2xl pt-2 font-UbuntuSemiBold text-center">
            Environment
          </p>
          <p className="text-dark text-xl md:text-2xl pb-2 font-UbuntuRegular text-center">
            We will focus on transitioning to become a Carbon Net Zero business by 2040.
          </p>
          <hr />

          <img src={require('../assets/images/partners.png')} className="h-40 m-auto mt-10" />
          <p className="text-dark text-xl md:text-2xl pt-2 font-UbuntuSemiBold text-center">
            Partners
          </p>
          <p className="text-dark text-xl md:text-2xl pb-2 font-UbuntuRegular text-center">
            We will partner with businesses who can support our social standards and environmental
            ambitions.
          </p>
          <hr />

          <img src={require('../assets/images/community.png')} className="h-40 m-auto mt-10" />
          <p className="text-dark text-xl md:text-2xl pt-2 font-UbuntuSemiBold text-center">
            Community
          </p>
          <p className="text-dark text-xl md:text-2xl pb-10 font-UbuntuRegular text-center">
            We will empower local artisans by giving them the right platform to showcase their
            products. We also want to make meaningful contributions to charities and communities
            close to us.
          </p>
        </div>
      </div>
    </>
  );
}
