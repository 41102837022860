import React from 'react';

export default function Vision() {
  return (
    <div className="bg-default px-6 md:px-16 mt-12 py-10">
      <p className="text-center text-2xl md:text-3xl pb-10 font-UbuntuSemiBold">
        Our Vision and Mission
      </p>
      <div className="grid md:grid-cols-2 md:space-x-5 ">
        <div className="bg-light border-light shadow-sm shadow-dark border-[10px] rounded-xl py-5 px-5 md:p-10 text-dark hover:text-black cursor-pointer">
          <p className="text-center text-2xl font-UbuntuSemiBold pb-2 text-dark">Vision</p>
          <ol className="list-disc">
            <li className="text-left font-UbuntuSemiBold text-lg">
              Pledge to making Earth a better place to live in through sustainable solutions
            </li>
            <li className="text-left font-UbuntuSemiBold text-lg">
              Providing quality products and services for our customers
            </li>
            <li className="text-left font-UbuntuSemiBold text-lg">
              Blending sustainability with cost-effectiveness
            </li>
          </ol>
        </div>
        <div className="bg-light border-light shadow-sm shadow-dark border-[10px] rounded-xl mt-10 md:mt-[unset] py-5 px-5 md:p-10 text-dark hover:text-black cursor-pointer">
          <p className="text-center text-2xl font-UbuntuSemiBold pb-2 text-dark">Mission</p>
          <ol className="list-disc">
            <li className="font-UbuntuSemiBold text-lg text-left">
              Create a platform for goods and services that promote sustainability as the basic
              objective
            </li>
            <li className="font-UbuntuSemiBold text-lg text-left">
              Build a robust supply-chain for bringing top notch solutions for the consumers of
              today
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
