import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function Blogs() {
  useEffect(() => {
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Green Rangers Consulting - Blogs & Vlogs</title>
        <meta name="description" content="Blogs" />
      </Helmet>
      <div className="">
        <div className="bg-blogs h-48 md:h-96 bg-contain bg-center bg-black bg-no-repeat flex justify-between">
          <div>
            <p className="text-dark md:text-light text-3xl md:text-5xl pl-6 py-14 md:py-32 font-UbuntuSemiBold absolute md:relative">
              Blogs & Vlogs
            </p>
          </div>
          {/* <img src={require('../assets/images/blog.jpg')} /> */}
        </div>
        <div className="py-10 px-6 md:px-16">
          <a
            className="cursor-pointer flex flex-col md:flex-row justify-start items-start border-b-[1px] border-dark pb-2 hover:shadow-xl"
            href="https://www.unep.org/regions/asia-and-pacific/regional-initiatives/supporting-resource-efficiency/sustainable-food"
            target="_blank"
            rel="noreferrer">
            <img src={require('../assets/images/sustainable.jpg')} className="h-40 w-72" />
            <div className="md:px-6">
              <p className="text-dark font-UbuntuSemiBold mt-2  text-xl md:text-2xl">
                Sustainable Food
              </p>
              <button className="text-white font-UbuntuSemiBold mt-2 text-md md:text-lg bg-gradient-to-r from-dark to-gray-400 pl-2 pr-2 md:pl-5 md:pr-10 py-1 md:py-2">
                Read More...
              </button>
            </div>
          </a>
          <a
            className="cursor-pointer flex flex-col md:flex-row justify-start items-start border-b-[1px] border-dark py-2 hover:shadow-xl"
            href="https://wwf.org.au/what-we-do/food/palm-oil/"
            target="_blank"
            rel="noreferrer">
            <img src={require('../assets/images/palm.png')} className="h-40 w-72" />
            <div className="md:px-6">
              <p className="text-dark font-UbuntuSemiBold mt-2  text-xl md:text-2xl">
                Dangers of Palm OIL farming expansion
              </p>
              <button className="text-white font-UbuntuSemiBold mt-2 text-md md:text-lg bg-gradient-to-r from-dark to-gray-400 pl-2 pr-2 md:pl-5 md:pr-10 py-1 md:py-2">
                Read More...
              </button>
            </div>
          </a>
          <a
            className="cursor-pointer flex flex-col md:flex-row justify-start items-start border-b-[1px] border-dark py-2 hover:shadow-xl"
            href="https://www.youtube.com/watch?v=m5ych9oDtk0"
            target="_blank"
            rel="noreferrer">
            <img src={require('../assets/images/carbon.jpg')} className="h-40 w-72" />
            <div className="md:px-6">
              <p className="text-dark font-UbuntuSemiBold mt-2  text-xl md:text-2xl">
                Carbon Taxation - Explained
              </p>
              <button className="text-white font-UbuntuSemiBold mt-2 text-md md:text-lg bg-gradient-to-r from-dark to-gray-400 pl-2 pr-2 md:pl-5 md:pr-10 py-1 md:py-2">
                Watch Here...
              </button>
            </div>
          </a>
          <a
            className="cursor-pointer flex flex-col md:flex-row justify-start items-start border-b-[1px] border-dark py-2 hover:shadow-xl"
            href="https://unfccc.int/process-and-meetings/the-paris-agreement"
            target="_blank"
            rel="noreferrer">
            <img src={require('../assets/images/paris.png')} className="h-40 w-72" />
            <div className="md:px-6">
              <p className="text-dark font-UbuntuSemiBold mt-2  text-xl md:text-2xl">
                What is Paris Agreement on Environment
              </p>
              <button className="text-white font-UbuntuSemiBold mt-2 text-md md:text-lg bg-gradient-to-r from-dark to-gray-400 pl-2 pr-2 md:pl-5 md:pr-10 py-1 md:py-2">
                Read More...
              </button>
            </div>
          </a>
          <a
            className="cursor-pointer flex flex-col md:flex-row justify-start items-start border-b-[1px] border-dark py-2 hover:shadow-xl"
            href="https://unfccc.int/blog/coral-20"
            target="_blank"
            rel="noreferrer">
            <img src={require('../assets/images/coral.jpg')} className="h-40 w-72" />
            <div className="md:px-6">
              <p className="text-dark font-UbuntuSemiBold mt-2 text-xl md:text-2xl">
                Why is Coral Reef important for our eco-system
              </p>
              <button className="text-white font-UbuntuSemiBold mt-2 text-md md:text-lg bg-gradient-to-r from-dark to-gray-400 pl-2 pr-2 md:pl-5 md:pr-10 py-1 md:py-2">
                Read More...
              </button>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}
