import React from 'react';

export default function InfoStart() {
  return (
    <div className="py-4 md:py-10">
      <p className="text-center text-3xl font-UbuntuSemiBold text-dark">Green Rangers Consulting</p>
      <div className="grid md:grid-cols-3 md:pt-10 md:overflow-hidden px-4 md:px-14">
        <div className="mt-10 md:m-10 bg-default md:h-80 md:w-80 rounded-xl md:p-5 shadow-2xl shadow-defaultDark cursor-pointer md:hover:my-2 mx-auto">
          <p className="text-center text-xl text-black font-UbuntuRegular p-8 md:p-2">
            As a one-stop-solution , Green Rangers Consulting Private Limited caters to a large
            basket of products, from across all industries. We can source any product, not only from
            the Indian soil, but from across the world. You Order, We Deliver !
          </p>
        </div>
        <div className="mt-10 md:m-10 bg-default md:h-80 md:w-80 rounded-xl md:p-5 shadow-2xl shadow-defaultDark cursor-pointer md:hover:my-2 mx-auto flex justify-center items-center">
          <p className="text-center text-xl text-black font-UbuntuRegular p-8 md:p-[unset]">
            Our robust supply chain ecosystem distributes variety of earth friendly products to our
            clients.
          </p>
        </div>
        <div className="mt-10 md:m-10 bg-default md:h-80 md:w-80 rounded-xl p-5 shadow-2xl shadow-defaultDark cursor-pointer hover:my-2 mx-auto">
          <p className="text-center text-xl text-black font-UbuntuRegular p-3 md:p-5">
            We support our partners with multiple options of Working Capital Loan , Term Loan ,
            Pre-Shipment and Post Shipment Loan, Trade Finance solutions and Regulatory & Compliance
            Services
          </p>
        </div>
        {/* <div className="m-10 bg-default h-80 w-80 rounded-xl p-5 shadow-2xl shadow-dark cursor-pointer hover:my-2">
          <p className="text-center text-xl text-black font-UbuntuRegular p-5">
            Besides that we have the highest grade of Rice and other agri products that is also one
            of our core food products. We will endeavour to bring the best in quality in food
            products which will bring healthy change in humankind.
          </p>
        </div>
        <div className="m-10 bg-default h-80 w-80 rounded-xl p-5 shadow-2xl shadow-dark cursor-pointer hover:my-2">
          <p className="text-center text-xl text-black font-UbuntuRegular p-5">
            We are sourcing consultants; we partner with global brands build and grow their business
            helping them in cost-effective vendor selection and production.
          </p>
        </div>
        <div className="m-10 bg-default h-80 w-80 rounded-xl p-5 shadow-2xl shadow-dark cursor-pointer hover:my-2">
          <p className="text-center text-xl text-black font-UbuntuRegular p-5">
            We also provide Trade consultancy services of variegated order for the newbies who are
            looking to make a mark in the field of Export and domestic trade services.
          </p>
        </div>
        <div className="m-10 bg-default h-80 w-80 rounded-xl p-5 shadow-2xl shadow-dark cursor-pointer hover:my-2">
          <p className="text-center text-xl text-black font-UbuntuRegular p-5">
            We provide financial solutions for your business and personal purpose. We help you with
            financial solutions and loans from multiple banks to grow your business.
          </p>
        </div>
        <div className="m-10 bg-default h-80 w-80 rounded-xl p-5 shadow-2xl shadow-dark cursor-pointer hover:my-2">
          <p className="text-center text-xl text-black font-UbuntuRegular p-5">
            We can create attractive contents for your business or personal goals and help you in
            marketing your products and services through digital marketing modes
          </p>
        </div> */}
      </div>
    </div>
  );
}
