import React, { useEffect } from 'react';
import Intro from '../Components/Home/Intro';
import InfoSection from '../Components/Home/InfoSection';

export default function Home() {
  useEffect(() => {
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);

  return (
    <div className="h-full">
      <Intro />
      <InfoSection />
    </div>
  );
}
