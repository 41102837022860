import React from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Header() {
  const [navbarOpen, setNavbarOpen] = useState(true);
  const routeName = useLocation();
  const navigate = useNavigate();
  const urlname = routeName.pathname.replace('/', '');
  return (
    <>
      <div className="md:flex md:justify-between px-4 md:px-16 md:w-full bg-dark md:fixed w-full z-10">
        <div className="flex flex-row justify-between items-center py-1">
          <div
            onClick={() => {
              navigate('/');
              setNavbarOpen(true);
            }}
            className="flex items-center">
            <div>
              <img
                src={require('../../assets/images/transparentlogo.png')}
                className="h-[70px] m-auto"
              />
              {/* <p className="text-2xl pt-2 pb-0 text-white">Green Ranger Consulting</p>
              <p className="text-center text-[10px] text-white">Earth-friendly Quality Solutions</p> */}
            </div>
          </div>
          {navbarOpen ? (
            <img
              src={require('../../assets/images/menu.png')}
              className="h-8 md:hidden"
              onClick={() => setNavbarOpen(false)}
            />
          ) : (
            <img
              src={require('../../assets/images/full-menu.png')}
              className="h-8 md:hidden"
              onClick={() => setNavbarOpen(true)}
            />
          )}
        </div>
        <ul className="hidden md:flex justify-between items-center">
          <li className="">
            <Link to="/">
              <p
                className={
                  `${
                    urlname == ''
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'px-10 py-2 text-white'
                }>
                Home
              </p>
            </Link>
          </li>
          <li className="">
            <Link to="/about-us">
              <p
                className={
                  `${
                    urlname == 'about-us'
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'px-10 py-2 text-white'
                }>
                About Us
              </p>
            </Link>
          </li>
          <li className="">
            <Link to="/services">
              <p
                className={
                  `${
                    urlname == 'services'
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'px-10 py-2 text-white'
                }>
                Services
              </p>
            </Link>
          </li>
          {/* <li className="">
            <Link to="/enquiry-form">
              <p
                className={
                  `${urlname == 'enquiry-form' ? 'text-[18px]' : 'text-[15px] opacity-85'} ` +
                  'px-10 py-2 text-white'
                }>
                Enquiry Form
              </p>
            </Link>
          </li> */}
          <li className="">
            <Link to="/contact-us">
              <p
                className={
                  `${
                    urlname == 'contact-us'
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'px-10 py-2 text-white'
                }>
                Contact Us
              </p>
            </Link>
          </li>
          <li className="">
            <Link to="/sustainability">
              <p
                className={
                  `${
                    urlname == 'sustainability'
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'px-10 py-2 text-white'
                }>
                Sustainability
              </p>
            </Link>
          </li>
          <li className="">
            <Link to="/blogs">
              <p
                className={
                  `${
                    urlname == 'blogs'
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'px-10 py-2 text-white'
                }>
                Blogs
              </p>
            </Link>
          </li>
        </ul>

        <ul className={`${navbarOpen ? 'hidden' : 'block'}` + ' md:hidden'}>
          <li className="">
            <div
              onClick={() => {
                navigate('/');
                setNavbarOpen(true);
              }}>
              <p
                className={
                  `${
                    urlname == ''
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
                }>
                Home
              </p>
            </div>
          </li>
          <li className="">
            <div
              onClick={() => {
                navigate('/about-us');
                setNavbarOpen(true);
              }}>
              <p
                className={
                  `${
                    urlname == 'about-us'
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
                }>
                About Us
              </p>
            </div>
          </li>
          <li className="">
            <div
              onClick={() => {
                navigate('/services');
                setNavbarOpen(true);
              }}>
              <p
                className={
                  `${
                    urlname == 'services'
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
                }>
                Services
              </p>
            </div>
          </li>
          <li className="">
            <div
              onClick={() => {
                navigate('/contact-us');
                setNavbarOpen(true);
              }}>
              <p
                className={
                  `${
                    urlname == 'contact-us'
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
                }>
                Contact Us
              </p>
            </div>
          </li>
          <li className="">
            <div
              onClick={() => {
                navigate('/sustainability');
                setNavbarOpen(true);
              }}>
              <p
                className={
                  `${
                    urlname == 'sustainability'
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
                }>
                Sustainability
              </p>
            </div>
          </li>
          <li className="">
            <div
              onClick={() => {
                navigate('/blogs');
                setNavbarOpen(true);
              }}>
              <p
                className={
                  `${
                    urlname == 'blogs'
                      ? 'text-[18px] font-UbuntuSemiBold'
                      : 'font-UbuntuRegular text-[15px] opacity-85'
                  } ` + 'md:px-10 pt-2 pb-1 md:py-2 text-white'
                }>
                Blogs
              </p>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}
